import React from "react";

const Song = ({ currentSong, isPlaying }) => {
  return (
    <div className="song-container">
      <img
        className={isPlaying ? "rotateSong" : ""}
        src={currentSong?.cover !== '' ? currentSong?.cover : "https://cdn.iconscout.com/icon/premium/png-512-thumb/music-disc-1831868-1554719.png"}
        alt=""
      />
      <h2>{currentSong?.name}</h2>
      <h3>{currentSong?.artist}</h3>
    </div>
  );
};

export default Song;
