import React, {useState, useEffect} from 'react'
import { v4 as uuidv4 } from "uuid";
import './styles/app.css';
const Add = () => {
    const [name, setname] = useState("")
    const [cover, setcover] = useState("")
    const [artist, setartist] = useState("")
    const [audio, setaudio] = useState("")
    const [id, setid] = useState(uuidv4())
    const [active, setactive] = useState("")
    const [isfile, setisfile] = useState(false)
    const [isImageFile, setisImageFile] = useState(false)
    const [addAlert, setaddAlert] = useState('')
    const [allSongs, setallSongs] = useState([])
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
       const url = "http://music.udokaokoye.com/mp3waves/get.php"
        fetch(url, {
          method: "POST"
        }).then((res) => res.json()).then((data) => {
          console.log(data);
          setallSongs(data)
        })
    }, [])

    const getSongs = () => {
      setisLoading(true)
      const url = "http://music.udokaokoye.com/mp3waves/get.php"
        fetch(url, {
          method: "POST"
        }).then((res) => res.json()).then((data) => {
          console.log(data);
          setallSongs(data)
          setisLoading(false)
        })
    }

    const handelSubmitt = () => {
      setisLoading(true)
        const formData = new FormData;
        formData.append("name", name);
        formData.append("cover", cover)
        formData.append("artist", artist)
        formData.append("audio", audio)
        formData.append("id", id)

        document.getElementById("audioFile").files[0] ? formData.append("audioFile", document.getElementById("audioFile").files[0]) : formData.append("audioFile", null)
        document.getElementById("imageFile").files[0] ? formData.append("imageFile", document.getElementById("imageFile").files[0]) : formData.append("imageFile", null)
        const url = "http://music.udokaokoye.com/mp3waves/add.php"

        fetch(url, {
            method: "POST",
            body: formData,
        }).then((res) => res.json()).then((data) => {
            setaddAlert(data)
            getSongs()
            setisLoading(false)
        })

       
    }

    const handelDelete = (id) => {
      setisLoading(true)
      const formData = new FormData;
      formData.append("id", id)
      const url = "http://music.udokaokoye.com/mp3waves/delete.php"

        fetch(url, {
            method: "POST",
            body: formData,
        }).then((res) => res.json()).then((data) => {
            getSongs()
            setisLoading(false)
        })
    }

    if (isLoading) {
      return (
        <div className="loader" style={{display: "flex", justifyContent: "center", alignItems: "center", height: '100vh'}}>
        <h1 style={{fontSize: 20}}>Loading...</h1>
      </div>
      )
    }
    return (
        <div className='container'>
          <h1>Add Song</h1>

          {addAlert !== '' ? (
            <div className="message">
            {addAlert}
          </div>
          ) : ""}
            
            <div className="field">
            <input onChange={(val) => setname(val.target.value)} value={name} placeholder="Name" />
            </div>

            <div className="field">
            <input type="file" className='file' disabled={cover !== ""} id="imageFile" accept="Image/*" onChange={() => setisImageFile(!isImageFile)} />
            <input disabled={isImageFile} onChange={(val) => setcover(val.target.value)} type="text" placeholder="cover" />
            </div>
            <div className="field">
            <input disabled={audio !== ""} className='file' id="audioFile" type="file" accept="audio/*" onChange={(event) => {
              setisfile(!isfile)
              // console.log(event.target.value)
            }
              
            } />
            <input disabled={isfile} onChange={(val) => setaudio(val.target.value)} placeholder="audio link" />
            </div>
            <div className="field">
            <input onChange={(val) => setartist(val.target.value)} type="text" placeholder="Artist" />
            <br /><br />
            <button className='submit' onClick={() => handelSubmitt()}>Submitt</button>
            </div>

            <h1>All Songs</h1>

            <div className="songs">
              {allSongs.map((song) => (
                <div style={{backgroundImage: `url(${song.cover})`}} className="song">
                  <div className="overlay">
                  <p>Name: {song.name}</p>
                  <p>Artist: {song.artist}</p>
                  <audio src={song.audio} controls></audio>
                  <input type="number" min={1} /> <button className='go'>Go </button> <button className='delete' onClick={() => handelDelete(song.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
        </div>
    )
}

export default Add
